<template>
  <div class="main">
    <div class="recipients item">
      <div class="title">收件人：</div>
      <div class="flex">
        <span
          v-for="(item, index) in form.receivers"
          :key="index"
          class="flex"
          style="margin: 5px"
        >
          <el-input v-model="form.receivers[index]" placeholder="请输入内容">
            <template v-if="form.receivers.length > 1" slot="append">
              <div @click="form.receivers.splice(index, 1)">删除</div>
            </template>
          </el-input>
        </span>
        <el-button
          size="mini"
          style="margin: 0 20px"
          type="primary"
          @click="form.receivers.push('')"
          >添加发送用户
        </el-button>
      </div>
    </div>
    <div class="item" style="align-items: center">
      <div class="title">主题：</div>
      <el-input v-model="form.subject" size="mini" />
    </div>
    <div class="file-list">
      <div class="file" @click="addFile">
        添加附件
        <span class="el-icon-folder-opened"></span>
      </div>
      <div v-for="(item, index) in fileList" :key="item.id" class="flex">
        <div class="margin-r-sm">{{ item.name }}</div>
        <div class="margin-r-sm">{{ unitConversion(item.size) }}</div>
        <div class="margin-r-sm">{{ item.ext }}</div>
        <div class="margin-r-sm">
          <el-button type="text" @click="delFile(index)">删除</el-button>
        </div>
      </div>
    </div>
    <div class="content item">
      <div class="title">正文：</div>
      <div>
        <editor :content="form.email_content" @onChange="onChange"></editor>
      </div>
    </div>
    <div class="submit" style="padding: 10px">
      <el-button
        :loading="submitLoading"
        size="mini"
        type="primary"
        @click="submit"
        >发送
      </el-button>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      append-to-body
      title="附件"
      width="60%"
    >
      <div class="margin-b-sm">
        <el-input
          v-model="searchData.name"
          class="margin-r-sm"
          placeholder="附件名称"
          style="width: 120px"
        />
        <el-button size="mini" type="primary" @click="search">搜索</el-button>
      </div>
      <div v-infinite-scroll="load" class="table">
        <el-table
          ref="multipleTable"
          v-loading="loading"
          :data="tableData"
          :header-cell-style="{ padding: 0 }"
          style="width: 100%; background: #fff"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column align="center" label="ID" property="id" width="80" />
          <el-table-column align="center" label="附件名称" property="name" />
          <el-table-column align="center" label="大小" property="size">
            <template slot-scope="scope">
              <span>{{ unitConversion(scope.row.size) }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="扩展名" property="ext" />
          <el-table-column align="center" label="上传者" property="user_name" />
          <el-table-column
            align="center"
            label="创建时间"
            property="created_at"
          />
          <el-table-column
            align="center"
            label="更新时间"
            property="updated_at"
          />
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addAttachment">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import editor from "@/components/editor.vue";

export default {
  components: { editor },
  data() {
    return {
      form: {
        receivers: [""],
        subject: "",
        email_content: "",
        email_attachment_file_ids: [],
      },
      dialogVisible: false,
      tableData: [],
      loading: false,
      page: 1,
      dialog: true,
      pageSize: 15,
      fileList: [],
      submitLoading: false,
      searchData: {},
    };
  },
  methods: {
    ...mapActions("mailbox", ["emailSend", "attachmentFileList"]),
    unitConversion(size) {
      return (size / 1024 / 1024).toFixed(2) + "M";
    },
    search() {
      this.dialog = true;
      this.page = 1;
      this.tableData = [];
      this.getFileList();
    },
    addAttachment() {
      this.dialogVisible = false;
    },
    async submit() {
      try {
        this.submitLoading = true;
        const { receivers, subject } = this.form;
        if (receivers[0] === "") {
          return this.$message.error("收件人必须");
        } else {
          for (let i = 0; i < receivers.length; i++) {
            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!regex.test(receivers[i]))
              return this.$message.error("第" + (i + 1) + "的邮箱错误！");
          }
        }
        if (subject === "") {
          return this.$message.error("主题必须");
        }
        const { res_info } = await this.emailSend(this.form);
        if (res_info !== "ok") return;
        this.$message.success("发送成功");
        await this.$router.push("/sendRecord");
      } finally {
        this.submitLoading = false;
      }
    },
    delFile(index) {
      this.fileList.splice(index, 1);
      this.form.email_attachment_file_ids.splice(index, 1);
    },
    onChange(content) {
      this.form.email_content = content;
    },
    async addFile() {
      this.dialog = true;
      let _this = this;
      _this.page = 1;
      this.tableData = [];
      this.searchData = {};
      await _this.getFileList();
      const rows = _this.getMatchingValues(
        this.tableData,
        this.form.email_attachment_file_ids
      );
      _this.$nextTick(() => {
        rows.forEach((row) => {
          _this.$refs.multipleTable.toggleRowSelection(row);
        });
      });
    },
    async load() {
      if (this.dialog) {
        let _this = this;
        ++_this.page;
        await _this.getFileList();
        const rows = _this.getMatchingValues(
          this.tableData,
          this.form.email_attachment_file_ids
        );
        _this.$nextTick(() => {
          rows.forEach((row) => {
            _this.$refs.multipleTable.toggleRowSelection(row);
          });
        });
      }
    },
    async getFileList() {
      try {
        this.loading = true;
        this.dialogVisible = true;
        let form = {
          page: this.page,
          pageSize: this.pageSize,
          ...this.searchData,
        };
        const { data } = await this.attachmentFileList(form);
        this.tableData = this.tableData.concat(data.list);

        if (this.page * this.pageSize > data.total) {
          this.dialog = false;
        }
      } finally {
        this.loading = false;
      }
    },
    getMatchingValues(array1, array2) {
      const result = [];
      for (let i = 0; i < array1.length; i++) {
        const item1 = array1[i];
        for (let j = 0; j < array2.length; j++) {
          const item2 = array2[j];
          if (item1.id === item2) {
            result.push(item1);
            break;
          }
        }
      }
      return result;
    },
    removeDuplicatesBySameId(arr) {
      var result = arr.reduce(function (unique, obj) {
        // 检查unique数组中是否已存在具有相同id的对象
        var exists = unique.some(function (item) {
          return item.id === obj.id;
        });

        // 如果不存在具有相同id的对象，则将当前对象添加到unique数组中
        if (!exists) {
          unique.push(obj);
        }

        return unique;
      }, []);

      return result;
    },
    handleSelectionChange(value) {
      this.fileList.push(...value);
      this.fileList = this.removeDuplicatesBySameId(this.fileList);

      this.form.email_attachment_file_ids.push(
        ...this.fileList.map((v) => v.id)
      );
      this.form.email_attachment_file_ids = Array.from(
        new Set(this.form.email_attachment_file_ids)
      );
    },
  },
};
</script>
<style scoped>
.main {
  padding: 10px;
}

.title {
  width: 4em;
}

.recipients {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
}

.file-list {
  padding: 5px 0;
  //border-bottom: 1px solid #eee;
}

.item {
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  justify-content: start;
}

.file {
  cursor: pointer;
}

.table {
  height: 50vh;
  overflow: auto;
}

.table::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

/* 滚动条滑块 */
.table::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #bbb;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25);
}
</style>